import React from "react";
import { Pressable, View, Text, StyleSheet, Platform } from "react-native";
import Colors from "../../constants/Colors";

const loadingWheel = "https://i.stack.imgur.com/kOnzy.gif";

const BUTTONS = [
	{
		title: "Not Readable",
	},
	{
		title: "Crossed Out",
	},
	{
		title: "Blank",
	},
	// {
	// 	title: "Incomplete",
	// },
	// {
	// 	title: "Segmentation Error",
	// },
	{
		title: "Incomplete",
	},
	// {
	// 	title: "Segmentation Error",
	// },
	// {
	// 	title: "Other",
	// },
];

const markImage = (image, mark) => {
	console.log(image);
	if (image.url !== loadingWheel) {
		fetch(
			"https://ri-backend-prd.byu-dept-fhtl-prd.amazon.byu.edu/mobile/image/mark",
			{
				method: "PUT",
				headers: {
					Accept: "application/json",
					"Content-Type": "application/json",
				},
				body: JSON.stringify({
					image: image,
					mark: mark.toUpperCase(),
				}),
			}
		);
	}
};

function Options(props) {
	return BUTTONS.map(function (button, i) {
		return (
			<Pressable
				children={({ pressed }) => (
					<View style={pressed ? styles.buttonPressedStyle : styles.item}>
						<Text numberOfLines={1} style={styles.itemText}>
							{button.title}
						</Text>
					</View>
				)}
				key={i}
				style={styles.pressableContainer}
				onPress={() => {
					markImage(props.image, button.title);
					props.setImageURL();
					props.resetTextInput();
					// if (Platform.OS != "web") {
					// 	props.checkTimestamp();
					// 	props.closeModal();
					// }
					props.incrementSessionIndexed();
					props.incrementTotalIndexed();
				}}
			></Pressable>
		);
	});
}

function MarkAsOptions(props) {
	return (
		<View style={Platform.OS == "web" ? styles.webContainer : styles.container}>
			<Options
				image={props.image}
				setImageURL={props.setImageURL}
				resetTextInput={props.resetTextInput}
				checkTimestamp={props.checkTimestamp}
				incrementSessionIndexed={props.incrementSessionIndexed}
				incrementTotalIndexed={props.incrementTotalIndexed}
				closeModal={props.closeModal}
			/>
		</View>
	);
}

const styles = StyleSheet.create({
	buttonPressedStyle: {
		backgroundColor: Colors.pressedPrimary,
		borderRadius: 8,
		margin: 7,
		padding: 15,
		justifyContent: "center",
		alignItems: "center",
		flexGrow: 1
	},
	container: {
		display: "flex",
		flexDirection: "row",
		flexWrap: "wrap",
		width: "100%",
	},
	webContainer: {
		margin: 2,
		padding: 15,
		justifyContent: "center",
		alignItems: "center",
		width: "100",
		flexDirection: "row",
		flexWrap: "wrap",
		flexGrow: 1
	},
	// item: {
	// 	backgroundColor: Colors.primary,
	// 	borderRadius: 8,
	// 	margin: 5,
	// 	padding: 15,
	// 	justifyContent: "center",
	// 	alignItems: "center",
	// 	minWidth: 150,
	// 	flexGrow: 1,
	// },
	item: {
		backgroundColor: Colors.primary,
		borderRadius: 8,
		margin: 7,
		padding: 15,
		width: "48%",
		justifyContent: "center",
		alignItems: "center",
		flexGrow: 1,
	},
	itemText: {
		color: Colors.white,
		fontSize: 20,
	},
	pressableContainer: {
		justifyContent: "center",
		alignItems: "center",
		flexGrow: 1,
		width: "48%",
		flexDirection: "row",
	},
});

export default MarkAsOptions;
