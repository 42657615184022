import React from "react";
import { StyleSheet, View } from "react-native";
import MarkAsOptions from "../General/MarkAsOptions";

function ControlPanel(props) {
	return (
		<View style={styles.container}>
			<MarkAsOptions
				image={props.image}
				setImageURL={props.setImageURL}
				resetTextInput={props.resetTextInput}
				incrementSessionIndexed={props.incrementSessionIndexed}
				incrementTotalIndexed={props.incrementTotalIndexed}
			/>
		</View>
	);
}

const styles = StyleSheet.create({
	container: {
		justifyContent: "center",
		alignItems: "center",
		width: "100%",
	},
	title: {
		fontSize: 32,
	},
});

export default ControlPanel;
