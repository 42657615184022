import React from "react";
import { Platform, StyleSheet, TextInput, View, Dimensions} from "react-native";
import SubmitButton from "../General/SubmitButton";
import Colors from "../../constants/Colors";
import SkipButton from "../General/SkipButton";
import ScreenSize from "../../constants/ScreenSize";

const { height } = Dimensions.get("window");

function SubmitTextInput(props) {
	return (
		<View style={styles.horizontalContainer}>
			<TextInput
				style={styles.input}
				onChangeText={props.onChangeText}
				textAlign={"center"}
				placeholder={"Enter correct data..."}
				value={props.text}
				autoCorrect={false}
				autoCapitalize={"none"}
				autoFocus={false}
				blurOnSubmit={false}
				enablesReturnKeyAutomatically={true} // this is ios specific
				clearButtonMode={"while-editing"} //this is ios specific
				onSubmitEditing={() => {
					props.setImageURL();
					props.resetTextInput();
					props.incrementSessionIndexed();
					props.incrementTotalIndexed();
				}}
			/>

			<View style={styles.skipButton}>
				<SkipButton
					setImageURL={props.setImageURL}
					image={props.image}
					resetTextInput={props.resetTextInput}
					text={props.text}
				/>
			</View>

			<View style={styles.submitButton}>
				<SubmitButton
					image={props.image}
					text={props.text}
					setImageURL={props.setImageURL}
					resetTextInput={props.resetTextInput}
					checkTimestamp={props.checkTimestamp}
					incrementSessionIndexed={props.incrementSessionIndexed}
					incrementTotalIndexed={props.incrementTotalIndexed}
				/>
			</View>
		</View>
	);
}

const styles = StyleSheet.create({
	horizontalContainer: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "space-between",
		marginHorizontal: 2,
	},
	input: {
		height: 50,
		marginVertical: 10,
		borderWidth: 2,
		borderRadius: 4,
		borderColor: Colors.primary,
		color: Colors.primary,
		fontWeight: "bold",
		alignSelf: "center",
		textAlign: "center",
		flex: Platform.OS == "web" && ScreenSize.isMobile == true ? 3 : 5,
	},
	skipButton: {
		flex: 1.5,
		marginHorizontal: 4,
	},
	submitButton: {
		flex:1.5
	},
	
});

export default SubmitTextInput;
