import React, { useEffect, useState } from "react";
import { Dimensions, SafeAreaView, StyleSheet, TextInput, View } from "react-native";
import AsyncStorage from "@react-native-async-storage/async-storage";
import WrittenImage from "../components/General/WrittenImage";
import Colors from "../constants/Colors";
import HeaderComponent from "../components/General/HeaderComponent";
import Buttons from "../components/Mobile/Buttons";
import * as StoreReview from "expo-store-review";

import SubmitTextInput from "../components/Web/SubmitTextInput";
import ControlPanel from "../components/Web/ControlPanel";
import Stats from "../components/Web/Stats";

import StatsButton from "../components/Mobile/StatsButton";



const { height } = Dimensions.get("window");

function MobileScreen(props) {
	const [isNewSession, updateIsNewSession] = useState(true);
	const [sessionIndexed, updateSessionIndexed] = useState(0);

	///////////////////////////////////////////////////////////////
	///////////////////////////////////////////////////////////////
	const askUserForReview = (totalSessionIndexed) => {
		if (totalSessionIndexed == 10) {
			if (StoreReview.isAvailableAsync()) {
				StoreReview.requestReview();
			}
		}
	};
	///////////////////////////////////////////////////////////////
	///////////////////////////////////////////////////////////////
	const checkTimestamp = async () => {
		try {
			const timestamp = await AsyncStorage.getItem("timestamp"); //gets timestamp of last indexed record
			if (timestamp == null) {
				updateIsNewSession(true); //if first time indexing
				return;
			}
			const newTimestamp = +new Date(); //create new timestamp for comparison
			if (newTimestamp - timestamp >= 1000 * 60 * 60) {
				updateIsNewSession(true); //if it's been an hour since last record indexed, it's a new session
			} else {
				updateIsNewSession(false);
			}
		} catch (error) {
			console.log("Error:", error);
		}
	};
	///////////////////////////////////////////////////////////////
	///////////////////////////////////////////////////////////////
	const incrementSessionIndexed = async () => {
		if (isNewSession == false) {
			updateSessionIndexed(sessionIndexed + 1);
		} else {
			updateIsNewSession(false);
			updateSessionIndexed(1);
		}
	};
	///////////////////////////////////////////////////////////////
	///////////////////////////////////////////////////////////////
	const incrementTotalIndexed = async () => {
		try {
			var stringTotalIndexed = await AsyncStorage.getItem("totalIndexed"); //string version
			var numberTotalIndexed = Number.parseInt(stringTotalIndexed); //convert to number
			numberTotalIndexed++; //increment by 1
			var newStringTotalIndexed = numberTotalIndexed.toString(); //convert back to string
			await AsyncStorage.setItem("totalIndexed", newStringTotalIndexed); // set new value in storage
			askUserForReview(numberTotalIndexed);
		} catch (error) {
			console.log("Error:", error);
		}
	};
	///////////////////////////////////////////////////////////////
	///////////////////////////////////////////////////////////////
	const resetTextInput = async () => {
		props.onChangeText("");
		const timestamp = +new Date();
		try {
			await AsyncStorage.setItem("timestamp", JSON.stringify(timestamp));
		} catch (error) {
			console.log("Error:", error);
		}
	};
	// This function is run upon rendering
	useEffect(() => {
		checkTimestamp();
	}, []); //empty array makes it so this function only runs once (mount and unmount)
	///////////////////////////////////////////////////////////////
	///////////////////////////////////////////////////////////////
	return (
		<>
			<SafeAreaView style={styles.safeArea} />
			<SafeAreaView style={styles.container}>
				<HeaderComponent />
				<SafeAreaView
					style={styles.container}>
						
					<SubmitTextInput
						image={props.image}
						text={props.text}
						setImageURL={props.setImageURL}
						onChangeText={props.onChangeText}
						resetTextInput={resetTextInput}
						checkTimestamp={checkTimestamp}
						incrementSessionIndexed={incrementSessionIndexed}
						incrementTotalIndexed={incrementTotalIndexed}
					/>
					<WrittenImage image={props.image} />
					<ControlPanel
						image={props.image}
						setImageURL={props.setImageURL}
						resetTextInput={resetTextInput}
						incrementSessionIndexed={incrementSessionIndexed}
						incrementTotalIndexed={incrementTotalIndexed}
					/>

					<StatsButton
						style={styles.buttons}
						sessionIndexed={sessionIndexed}
						isNewSession={isNewSession}
						checkTimestamp={checkTimestamp}
					/>	
				</SafeAreaView>
			</SafeAreaView>
		</>
	);
}
const styles = StyleSheet.create({
	buttons: {
		flex: 1,
		alignItems: "center",
		justifyContent: "center",
		margin: 2,
		//margin:10
	},
	buttonContainer: {
		flex:1,
		backgroundColor: "red",
		alignItems: "center",
		justifyContent: "space-evenly",
	},
	container: {
		flex: 1,
		justifyContent: "space-evenly"
	},
	input: {
		height: height * 0.07,
		marginVertical: 12,
		marginHorizontal: 2,
		borderWidth: 2,
		borderRadius: 8,
		borderColor: Colors.primary,
		color: Colors.primary,
		fontWeight: "bold",
	},
	safeArea: {
		backgroundColor: Colors.primary,
	},
});

export default MobileScreen;
