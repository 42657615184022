export default {
	primary: "#4A69D9",
	secondary: "#575757",
	white: "#fff",
	black: "#000",
	green: "#17d111",
	pressedGreen: "#10940c",
	pressedPrimary: "#2b377a",
	disabledGrey: "#bfbfbf",
	treeBlue: "#1c6fba",
};
