import React from "react";
import { Platform, StyleSheet, Text, View } from "react-native";
import Colors from "../../constants/Colors";
import Logo from "./Logo";
import Info from "./Info";
import Help from "./Help";

function HeaderComponent() {
	if (Platform.OS == "web") {
		return (
			<View style={styles.background}>
				<View style={styles.horizontalContainerWeb}>
					<Logo />
					<Text style={styles.title}>INDEXING GO</Text>
				</View>
			</View>
		);
	} else {
		return (
			<View style={styles.background}>
				<View style={styles.horizontalContainerMobile}>
					<Info />
					<Logo />
					<Help />
				</View>
			</View>
		);
	}
}

const styles = StyleSheet.create({
	background: {
		backgroundColor: Colors.primary,
		width: "100%",
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: Platform.OS == "web" ? "flex-start" : "center",
		paddingTop: Platform.OS === "android" ? 25 : 0,
		elevation: Platform.OS === "web" ? 10 : 0,
		shadowRadius: Platform.OS == "web" ? 10 : 0,
	},
	horizontalContainerWeb: {
		flex: 1,
		alignItems: "center",
		paddingVertical: Platform.OS == "web" ? 15 : 0,
		marginHorizontal: Platform.OS == "web" ? 20 : 0,
	},
	horizontalContainerMobile: {
		flex: 1,
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "space-between",
		paddingVertical: Platform.OS == "web" ? 15 : 0,
		marginHorizontal: Platform.OS == "web" ? 20 : 10,
	},
	title: {
		color: Colors.white,
		marginHorizontal: 20,
		fontSize: 25,
		fontWeight: "700",
	}
});

export default HeaderComponent;
