import React from "react";
import { StyleSheet, Image, View, Dimensions, Platform } from "react-native";
import ScreenSize from "../../constants/ScreenSize";

import Colors from "../../constants/Colors";

const { width } = Dimensions.get("window");
const height = width * 0.80; //80%

const WrittenImage = (props) => {
	if (Platform.OS == "web") {
		return (
			<View style={styles.container}>
				<Image
					style={styles.image}
					source={{
						uri: props.image.url,
						width: 500,
						height: ScreenSize.isLargeScreen ? 500 : 300,
					}}
				/>
			</View>
		);
	} else {
		return (
			<Image
				style={styles.mobileImage}
				source={{ uri: props.image.url, width: width, height: height }}
			/>

		);
	}
};
const styles = StyleSheet.create({
	container: {
		margin: 2,
		justifyContent: "center",
		alignItems: "center",
		backgroundColor: Colors.secondary,
		borderRadius: 8,
	},
	image: {
		resizeMode: "contain",
		width: "100%",
	},
	mobileImage: {
		resizeMode: "contain",
		width: "100%",
		backgroundColor: Colors.secondary,
	}
});

export default WrittenImage;
