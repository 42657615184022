import React, { useState } from "react";
import { View, StyleSheet } from "react-native";
import HeaderComponent from "../components/General/HeaderComponent";
import Stats from "../components/Web/Stats";
import WrittenImage from "../components/General/WrittenImage";
import ControlPanel from "../components/Web/ControlPanel";
import SubmitTextInput from "../components/Web/SubmitTextInput";
import Dimensions from "../constants/ScreenSize";

function WebScreen(props) {
	const session =
		sessionStorage.getItem("sessionIndexed") === undefined ||
		sessionStorage.getItem("sessionIndexed") === null
			? 0
			: sessionStorage.getItem("sessionIndexed");
	const [sessionIndexed, updateSessionIndexed] = useState(session);

	const most =
		localStorage.getItem("mostIndexed") === undefined ||
		localStorage.getItem("mostIndexed") === null
			? 0
			: localStorage.getItem("mostIndexed");
	const [mostIndexed, updateMostIndexed] = useState(most);

	const total =
		localStorage.getItem("totalIndexed") === undefined ||
		localStorage.getItem("totalIndexed") === null
			? 0
			: localStorage.getItem("totalIndexed");
	const [totalIndexed, updateTotalIndexed] = useState(total);

	///////////////////////////////////////////////////////////////
	///////////////////////////////////////////////////////////////
	const incrementSessionIndexed = () => {
		var stringSessionIndexed = sessionStorage.getItem("sessionIndexed"); //string version
		if (!isNaN(stringSessionIndexed) && stringSessionIndexed != null) {
			var numberSessionIndexed = Number.parseInt(stringSessionIndexed); //convert to number
			numberSessionIndexed++; //increment by 1
			incrementMostIndexed(numberSessionIndexed);
			var newStringSessionIndexed = numberSessionIndexed.toString(); //convert back to string
			updateSessionIndexed(newStringSessionIndexed);
			sessionStorage.setItem("sessionIndexed", newStringSessionIndexed); // set new value in storage
		} else {
			updateSessionIndexed(1);
			sessionStorage.setItem("sessionIndexed", 1);
			incrementMostIndexed(1);
		}
	};
	///////////////////////////////////////////////////////////////
	///////////////////////////////////////////////////////////////
	const incrementMostIndexed = (numberSessionIndexed) => {
		var stringMostIndexed = localStorage.getItem("mostIndexed"); //string version
		if (!isNaN(stringMostIndexed) && stringMostIndexed != null) {
			var numberMostIndexed = Number.parseInt(stringMostIndexed); //convert to number
			if (numberSessionIndexed >= numberMostIndexed) {
				numberMostIndexed = numberSessionIndexed; //increment by 1
				var newStringMostIndexed = numberMostIndexed.toString(); //convert back to string
				updateMostIndexed(newStringMostIndexed);
				localStorage.setItem("mostIndexed", newStringMostIndexed); // set new value in storage
			}
		} else {
			updateMostIndexed(1);
			localStorage.setItem("mostIndexed", 1);
		}
	};
	///////////////////////////////////////////////////////////////
	///////////////////////////////////////////////////////////////
	const incrementTotalIndexed = () => {
		var stringTotalIndexed = localStorage.getItem("totalIndexed"); //string version
		if (!isNaN(stringTotalIndexed) && stringTotalIndexed != null) {
			var numberTotalIndexed = Number.parseInt(stringTotalIndexed); //convert to number
			numberTotalIndexed++; //increment by 1
			var newStringTotalIndexed = numberTotalIndexed.toString(); //convert back to string
			updateTotalIndexed(newStringTotalIndexed);
			localStorage.setItem("totalIndexed", newStringTotalIndexed); // set new value in storage
		} else {
			updateTotalIndexed(1);
			localStorage.setItem("totalIndexed", 1);
		}
	};
	return (
		<View style={styles.container}>
			<HeaderComponent />
			<View style={styles.bodyContainer}>
				<SubmitTextInput
					image={props.image}
					text={props.text}
					setImageURL={props.setImageURL}
					onChangeText={props.onChangeText}
					resetTextInput={props.resetTextInput}
					incrementSessionIndexed={incrementSessionIndexed}
					incrementTotalIndexed={incrementTotalIndexed}
				/>
				<WrittenImage image={props.image} />
				<ControlPanel
					image={props.image}
					setImageURL={props.setImageURL}
					resetTextInput={props.resetTextInput}
					incrementSessionIndexed={incrementSessionIndexed}
					incrementTotalIndexed={incrementTotalIndexed}
				/>
				<Stats
					sessionIndexed={sessionIndexed}
					mostIndexed={mostIndexed}
					totalIndexed={totalIndexed}
				/>
			</View>
		</View>
	);
}

const styles = StyleSheet.create({
	bodyContainer: {
		marginTop: 20,
		width: Dimensions.isLargeScreen ? 1200 : 600,
	},
	container: {
		alignItems: "center",
		justifyContent: "center",
	},
});

export default WebScreen;
