import React, { useState } from "react";
import { Modal, StyleSheet, Text, View } from "react-native";
import Colors from "../../constants/Colors";
import CloseButton from "./CloseButton";
import CustomButton from "../General/CustomButton";
import MarkAsOptions from "../General/MarkAsOptions";

function MarkAsButton(props) {
	const [modalVisible, setModalVisible] = useState(false);

	return (
		<View style={styles.container}>
			<Modal
				animationType="slide"
				transparent={true}
				visible={modalVisible}
				onRequestClose={() => setModalVisible(!modalVisible)}
			>
				<View style={styles.centeredView}>
					<View style={styles.modalView}>
						<Text style={styles.modalText}>Mark as...</Text>
						<MarkAsOptions
							image={props.image}
							setImageURL={props.setImageURL}
							resetTextInput={props.resetTextInput}
							checkTimestamp={props.checkTimestamp}
							incrementSessionIndexed={props.incrementSessionIndexed}
							incrementTotalIndexed={props.incrementTotalIndexed}
							closeModal={() => setModalVisible(!modalVisible)}
						/>
						<CloseButton onPress={() => setModalVisible(!modalVisible)} />
					</View>
				</View>
			</Modal>
			<CustomButton
				text={"Mark as..."}
				onPress={() => setModalVisible(!modalVisible)}
			/>
		</View>
	);
}

const styles = StyleSheet.create({
	container: {
		width: "100%",
		alignItems: "center",
	},
	centeredView: {
		flex: 1,
		justifyContent: "center",
		alignItems: "center",
	},
	modalText: {
		fontSize: 36,
		marginBottom: 15,
		textAlign: "center",
	},
	modalView: {
		margin: 20,
		backgroundColor: "white",
		borderRadius: 8,
		padding: 35,
		alignItems: "center",
		shadowColor: Colors.black,
		shadowOffset: {
			width: 0,
			height: 2,
		},
		shadowOpacity: 0.25,
		shadowRadius: 4,
		elevation: 5,
		width: "90%",
		justifyContent: "center",
	},
});

export default MarkAsButton;
