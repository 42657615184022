import React from "react";
import { Pressable, StyleSheet, Text, View } from "react-native";
import Colors from "../../constants/Colors";

function CloseButton(props) {
	return (
		<Pressable
			children={({ pressed }) => (
				<View style={pressed ? styles.pressedButton : styles.button}>
					<Text style={styles.buttonText}>Close</Text>
				</View>
			)}
			onPress={props.onPress}
			style={styles.pressableContainer}
		></Pressable>
	);
}

const styles = StyleSheet.create({
	button: {
		borderRadius: 8,
		elevation: 2,
		backgroundColor: Colors.primary,
		alignItems: "center",
		padding: 10,
	},
	buttonText: {
		color: Colors.white,
	},
	pressableContainer: {
		width: "100%",
		alignItems: "center",
		marginTop: 40,
	},
	pressedButton: {
		borderRadius: 8,
		elevation: 2,
		backgroundColor: Colors.pressedPrimary,
		alignItems: "center",
		padding: 10,
	},
});

export default CloseButton;
