import React from "react";
import {Alert, Pressable, Text, StyleSheet} from "react-native";
import * as WebBrowser from "expo-web-browser";

const Info = () => {

    function openAbout() { WebBrowser.openBrowserAsync("https://familytech.byu.edu/resources.html"); }
    function openContact() { WebBrowser.openBrowserAsync("https://familytech.byu.edu/contact.html"); }

    const infoAlert = () =>
        Alert.alert(
            "Information",
            "Relevant BYU FHTL pages",
            [
                {text: "Information", onPress: openAbout},
                {text: "Contact Us", onPress: openContact},
                {text: "Close"}
            ]
        );

    return (
        <Pressable onPress={infoAlert}>
            <Text style={styles.text}>About</Text>
        </Pressable>
    )
}

const styles = StyleSheet.create({
    text: {
        color: "white",
        fontSize: 18
    },
});

export default Info;