import React from "react";
import { StyleSheet, Text, View } from "react-native";
import Icon from "react-native-vector-icons/Entypo";
import Colors from "../../constants/Colors";

function Stats(props) {
	return (
		<View style={styles.container}>
			<Text style={styles.titleText}>
				Stats
				<Icon name="medal" size={36} color={Colors.primary} />
			</Text>
			<Text style={styles.statsText}>
				Indexed in this session: {props.sessionIndexed}{" "}
			</Text>
			<Text style={styles.statsText}>
				Most indexed in a session: {props.mostIndexed}{" "}
			</Text>
			<Text style={styles.statsText}>
				Total records indexed: {props.totalIndexed}{" "}
			</Text>
		</View>
	);
}

const styles = StyleSheet.create({
	container: {
		justifyContent: "center",
		alignItems: "center",
		alignSelf: "center",
		backgroundColor: Colors.white,
		borderColor: Colors.primary,
		borderWidth: 2,
		borderRadius: 8,
		paddingVertical: 10,
		marginHorizontal: 2,
		width: "100%",
	},
	titleText: {
		color: Colors.secondary,
		fontSize: 36,
		textAlign: "center",
	},
	statsText: {
		color: Colors.secondary,
		fontWeight: "bold",
		fontSize: 16,
	},
});

export default Stats;
