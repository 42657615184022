import React from "react";
import { Dimensions, Pressable, StyleSheet, Text, View, SafeAreaView } from "react-native";
import Colors from "../../constants/Colors";

const { height } = Dimensions.get("window");

function CustomButton(props) {
	return (
		<Pressable
			children={({ pressed }) => (
				<SafeAreaView style={pressed ? styles.pressedItem : styles.item}>
					<Text style={styles.itemText}>
						{props.text} {props.icon}{" "}
					</Text>
				</SafeAreaView>
			)}
			onPress={props.onPress}
			style={styles.pressableContainer}
			disabled={props.disabled}
		></Pressable>
	);
}

const styles = StyleSheet.create({

	item: {
		backgroundColor: Colors.primary,
		borderRadius: 8,
		justifyContent: "center",
		alignItems: "center",
		width: "100%",
		paddingVertical: height / 45,
	},

	itemText: {
		color: Colors.white,
		fontSize: height / 40,
		textAlign: "center"
	},
	pressableContainer: {
		width: "100%",
		alignItems: "center",
	},
	pressedItem: {
		backgroundColor: Colors.pressedPrimary,
		borderRadius: 8,
		justifyContent: "center",
		alignItems: "center",
		width: "100%",
		marginHorizontal: 2,
		paddingVertical: height / 45,
	},
});

export default CustomButton;
