import React, { useState } from "react";
import { Dimensions, Modal, StyleSheet, Text, View } from "react-native";
import AsyncStorage from "@react-native-async-storage/async-storage";
import CustomButton from "../General/CustomButton";
import CloseButton from "./CloseButton";
import Icon from "react-native-vector-icons/Entypo";
import Colors from "../../constants/Colors";

const { height } = Dimensions.get("window");

function StatsButton(props) {
	const [modalVisible, setModalVisible] = useState(false);
	const [mostIndexed, updateMostIndexed] = useState("0");
	const [totalIndexed, updateTotalIndexed] = useState("0");

	const getMostIndexed = async () => {
		try {
			const stringMostIndexed = await AsyncStorage.getItem("mostIndexed");
			if (!isNaN(stringMostIndexed) && stringMostIndexed != null) {
				//checks if mostIndexed already exists in AsyncStorage
				var numberMostIndexed = Number.parseInt(stringMostIndexed); //convert to number
				if (props.sessionIndexed > numberMostIndexed) {
					//checks if user beat old record, is so -> update new record
					await AsyncStorage.setItem(
						"mostIndexed",
						props.sessionIndexed.toString()
					);
					updateMostIndexed(props.sessionIndexed);
					return props.sessionIndexed;
				} else {
					return numberMostIndexed; // if didn't beat record, keep original
				}
			} else {
				// await AsyncStorage.removeItem("mostIndexed");
				await AsyncStorage.setItem(
					"mostIndexed",
					props.sessionIndexed.toString()
				);
				return props.sessionIndexed.toString(); // if there is no stringMostIndexed, return 0
			}
		} catch (error) {
			console.log(error);
		}
	};
	const getTotalIndexed = async () => {
		try {
			const value = await AsyncStorage.getItem("totalIndexed");
			if (!isNaN(value) && value != null) {
				//if a value in totalIndexed was already stored
				return value;
			} else {
				await AsyncStorage.setItem(
					"totalIndexed",
					props.sessionIndexed.toString()
				);
				return props.sessionIndexed.toString(); // if there is no value, return 0
			}
		} catch (error) {
			console.log(error);
		}
	};

	const updateStats = async () => {
		var stringMostIndexed = await getMostIndexed();
		updateMostIndexed(stringMostIndexed);

		var stringTotalIndexed = await getTotalIndexed();
		updateTotalIndexed(stringTotalIndexed);
	};

	function Stats() {
		return (
			<View>
				<Text style={styles.modalText}>
					Stats
					<Icon name="medal" size={36} color={Colors.black} />
				</Text>
				<View style={styles.statsContainer}>
					<View style={styles.horizontalRule} />
					<Text style={styles.statsText}>
						Indexed in this session:{" "}
						{props.isNewSession == true ? 0 : props.sessionIndexed}
					</Text>
					<Text style={styles.statsText}>
						Most indexed in a session: {mostIndexed}
					</Text>
					<Text style={styles.statsText}>
						Total records indexed: {totalIndexed}
					</Text>
				</View>
			</View>
		);
	}
	return (
		<View style={styles.container}>
			<Modal
				animationType="slide"
				transparent={true}
				visible={modalVisible}
				onRequestClose={() => setModalVisible(!modalVisible)}
			>
				<View style={styles.centeredView}>
					<View style={styles.modalView}>
						<Stats />
						<CloseButton onPress={() => setModalVisible(!modalVisible)} />
					</View>
				</View>
			</Modal>
			<CustomButton
				text={"Stats"}
				icon={<Icon name="medal" size={height / 30} color="white" />}
				onPress={() => {
					props.checkTimestamp();
					updateStats();
					setModalVisible(!modalVisible);
				}}
			/>
		</View>
	);
}

const styles = StyleSheet.create({
	container: {
		width: "95%",
		alignItems: "center",
		alignSelf: "center",
		marginHorizontal: 10,
	},
	horizontalRule: {
		borderBottomColor: "black",
		borderBottomWidth: StyleSheet.hairlineWidth,
		alignSelf: "stretch",
	},
	modalText: {
		fontSize: 36,
		margin: 30,
		textAlign: "center",
	},
	statsContainer: {
		width: "100%",
		height: 150,
		flexDirection: "column",
		justifyContent: "space-between",
	},
	statsText: {
		fontWeight: "bold",
		fontSize: 16,
	},
	centeredView: {
		flex: 1,
		justifyContent: "center",
		alignItems: "center",
	},
	modalView: {
		margin: 20,
		backgroundColor: "white",
		borderRadius: 8,
		padding: 35,
		alignItems: "center",
		shadowColor: Colors.black,
		shadowOffset: {
			width: 0,
			height: 2,
		},
		shadowOpacity: 0.25,
		shadowRadius: 4,
		elevation: 5,
		width: "90%",
	},
});

export default StatsButton;
