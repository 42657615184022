import React, { useEffect, useState } from "react";
import { Platform, StyleSheet, View,TouchableWithoutFeedback, Keyboard } from "react-native";
import MobileScreen from "./app/screens/MobileScreen";
import WebScreen from "./app/screens/WebScreen";
import MobileWebScreen from "./app/screens/MobileWebScreen";
import ScreenSize from "./app/constants/ScreenSize";

export default function App() {
	const loadingWheel = "https://i.stack.imgur.com/kOnzy.gif";
	const [images, setImages] = useState([{ url: loadingWheel }]);
	const [image, setImage] = useState({ url: loadingWheel });
	const [text, onChangeText] = useState("");

	///////////////////////////////////////////////////////////////
	///////////////////////////////////////////////////////////////
	const resetTextInput = async () => {
		onChangeText("");
	};

	///////////////////////////////////////////////////////////////
	///////////////////////////////////////////////////////////////
	const setImageURL = () => {
		if (images.length == 5) {
			getImages();
		}
		if (images.length == 0) {
			setImage({ url: loadingWheel }); //loading wheel
		} else {
			setImage(images.shift());
		}
	};
	///////////////////////////////////////////////////////////////
	///////////////////////////////////////////////////////////////
	const getImages = async () => {
		await fetch(
			"https://ri-backend-prd.byu-dept-fhtl-prd.amazon.byu.edu/mobile/images"
		)
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				console.log("data:", data);
				setImages(data.splice(1));
				setImage(data[0]);
			});
	};
	///////////////////////////////////////////////////////////////
	///////////////////////////////////////////////////////////////
	// This function is run upon rendering
	useEffect(() => {
		getImages();
	}, []); // empty array makes it so this function only runs once (mount and unmount)
	///////////////////////////////////////////////////////////////
	///////////////////////////////////////////////////////////////
	if (Platform.OS == "web" && ScreenSize.isMobile == true) {
		return (
			<MobileWebScreen
				setImageURL={setImageURL}
				getImages={getImages}
				image={image}
				text={text}
				onChangeText={onChangeText}
				resetTextInput={resetTextInput}
			/>
		);
	} else if (Platform.OS == "web") {
		return (
			<WebScreen
				setImageURL={setImageURL}
				getImages={getImages}
				image={image}
				text={text}
				onChangeText={onChangeText}
				resetTextInput={resetTextInput}
			/>
		);
	} else {
		return (
			<TouchableWithoutFeedback onPress={() => Keyboard.dismiss()}>
				<View style={styles.container}>
					<MobileScreen
						setImageURL={setImageURL}
						getImages={getImages}
						image={image}
						text={text}
						onChangeText={onChangeText}
						resetTextInput={resetTextInput}
						/>
				</View>
			</TouchableWithoutFeedback>
		);
	}
}

const styles = StyleSheet.create({
	container: {
		flex: 1,
	},
});
