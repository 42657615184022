import React, { useState } from "react";
import { Alert, Image, Modal, Pressable, StyleSheet, Text, View, SafeAreaView, FlatList } from "react-native";
import Colors from "../../constants/Colors";

import Swiper from "react-native-swiper/src";

const Help = () => {
  const [modalVisible, setModalVisible] = useState(false);

  //This is the list of instructions that will be put on the first slide of the help modal.
  const textInstructions = [
    { key: "This is a simple app to make indexing as easy as possible! Here's how it works:" },
    { key: "1. An image will appear on the screen." },
    { key: "2. If possible, type in a transcription of the image and press the check button to submit it." },
    { key: "3. If you can't figure out what the image says, mark it as Not Readable, Crossed Out, Blank, or Incomplete by tapping the corresponding buttons. Or simply skip to a new image with the skip button." },
  ]

  //this array contains all the information that will be put into the different slides of the swiper component.
  const helpSlides = [
    { header: "Not Readable", image: require("../../assets/not_readable_example.jpg"), text: "If you are unable to read the contents of the image, choose this option." },
    { header: "Crossed Out", image: require("../../assets/crossed_out_example.jpg"), text: "If the contents of the image are crossed out, choose this option." },
    { header: "Blank", image: require("../../assets/blank_example.jpg"), text: "If the image record appears to be blank, choose this option." },
    { header: "Incomplete", image: require("../../assets/incomplete_example.jpg"), text: "If the image does not fully appear, i.e. the top, bottom, left, or right side seems to be cut off or missing, choose this option." }
  ]

  //this function will build the slides needed for the Swiper Component.
  const getSlides = () => {
    let helpSlideViews = []

    //add the initial instructions slide
    helpSlideViews.push(
      <View style={styles.slide}>
        <Text style={styles.instructionHeader}>Welcome to Indexing GO!</Text>
        <FlatList
          data={textInstructions}
          renderItem={({ item }) => <Text style={styles.instructionListItem}>{item.key}</Text>}
        />
      </View>
    )

    //add a help slide to describe each of the marking button options(not readable, crossed out...)
    for (let helpSlide of helpSlides) {
      helpSlideViews.push(
        <View style={styles.slide}>
          <Text style={styles.modalTextHeader}>{helpSlide.header}</Text>
          <Image style={styles.helpImage} source={helpSlide.image} />
          <Text style={styles.modalTextDescription}>{helpSlide.text}</Text>
        </View>
      )
    }

    return helpSlideViews;
  }

  const infoAlert = () => {
    Alert.alert("Help");
  };

  return (
    <Pressable onPress={() => { setModalVisible(!modalVisible); }}>
      <Modal
        animationType="slide"
        transparent={true}
        visible={modalVisible}
        onRequestClose={() => setModalVisible(!modalVisible)}
      >
        <SafeAreaView style={styles.centeredView}>
          <View style={styles.modalView}>
            <Swiper loop={false} paginationStyle={{ position: "absolute", bottom: 5, }}>
              {getSlides()}
            </Swiper>
            <View style={styles.closeButtonView}>
              <Pressable
                children={({ pressed }) => (
                  <View style={pressed ? styles.closeButtonPressed : styles.closeButton}>
                    <Text style={styles.text}>Close</Text>
                  </View>
                )}
                onPress={() => { setModalVisible(!modalVisible); }}>
              </Pressable>
            </View>
          </View>
        </SafeAreaView>
      </Modal>
      <Text style={styles.text}>Help</Text>
    </Pressable>
  )
}

const styles = StyleSheet.create({
  slide: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  instructionHeader: {
    fontSize: 34,
    fontWeight: "bold",
    textAlign: "center",
  },
  modalTextHeader: {
    flex: .5,
    fontSize: 24,
    fontWeight: "bold",
  },
  helpImage: {
    flex: 5,
    resizeMode: "contain",
    width: "100%",
  },
  modalTextDescription: {
    flex: 1,
    textAlign: "center",
  },
  closeButtonView: {
    alignSelf: "stretch",
  },
  closeButton: {
    borderRadius: 8,
    elevation: 2,
    backgroundColor: Colors.primary,
    alignItems: "center",
    alignSelf: "stretch",
    padding: 10,
  },
  closeButtonPressed: {
    borderRadius: 8,
    elevation: 2,
    backgroundColor: Colors.pressedPrimary,
    alignItems: "center",
    alignSelf: "stretch",
    padding: 10,
  },
  instructionListItem: {
    marginTop: 20,
    fontSize: 15
  },
  modalView: {
    margin: 20,
    backgroundColor: "white",
    borderRadius: 20,
    padding: 35,
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
  },
  text: {
    color: "white",
    fontSize: 18
  },
});

export default Help;
