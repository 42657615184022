import React from "react";
import {
	Dimensions,
	Keyboard,
	Platform,
	Pressable,
	StyleSheet,
	Text,
	View,
} from "react-native";
import Icon from "react-native-vector-icons/Entypo";
import Colors from "../../constants/Colors";

const { height } = Dimensions.get("window");
const loadingWheel = "https://i.stack.imgur.com/kOnzy.gif";

const submitImages = (image, transcription) => {
	console.log(image);
	console.log(transcription);
	if (image.url !== loadingWheel) {
		fetch(
			"https://ri-backend-prd.byu-dept-fhtl-prd.amazon.byu.edu/mobile/image/text",
			{
				method: "PUT",
				headers: {
					Accept: "application/json",
					"Content-Type": "application/json",
				},
				body: JSON.stringify({
					image: image,
					transcription: transcription,
				}),
			}
		);
	}
};

function SubmitButton(props) {
	let style;
	if (props.text) {
		style = {backgroundColor: Colors.green};
	} else {
		style = {backgroundColor: Colors.disabledGrey};
	}

	if (Platform.OS == "web") {
		return (
			<Pressable
				children={({ pressed }) => (
					<View
						style={
							pressed
								? [styles.submitWeb, styles.submitPressedStyle]
								: [styles.submitWeb, style]
						}
					>
						<Text>
							<Icon name={"check"} size={24} color={Colors.white} />
						</Text>
					</View>
				)}
				disabled={!props.text}
				onPress={() => {
					submitImages(props.image, props.text);
					props.setImageURL();
					props.resetTextInput();
					props.incrementSessionIndexed();
					props.incrementTotalIndexed();
				}}
			/>
		);
	} else {
		return (
			<Pressable
				children={({ pressed }) => (
					<View
						style={
							pressed
								? [styles.submitMobile, {backgroundColor: Colors.pressedGreen}]
								: [styles.submitMobile, style]
						}
					>
						<Text>
							<Icon name={"check"} size={24} color={Colors.white} />
						</Text>
					</View>
				)}
				disabled={!props.text}
				onPress={() => {
					submitImages(props.image, props.text);
					props.setImageURL();
					props.resetTextInput();
					props.checkTimestamp();
					props.incrementSessionIndexed();
					props.incrementTotalIndexed();
					Keyboard.dismiss();
				}}
				style={styles.container}
			/>
		);
	}
}

const styles = StyleSheet.create({
	container: {
		width: "100%",
		alignItems: "center",
	},
	submitMobile: {
		backgroundColor: Colors.primary,
		borderRadius: 8,
		justifyContent: "center",
		alignItems: "center",
		width: "100%",
		height: 50,
	},
	submitPressedStyle: {
		backgroundColor: Colors.pressedGreen,
	},
	submitWeb: {
		borderRadius: 8,
		justifyContent: "center",
		alignItems: "center",
		width: "100%",
		height: 50,
	},
});

export default SubmitButton;
