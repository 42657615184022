import React from "react";
import { Image, Platform, StyleSheet } from "react-native";

function Logo() {
	return (
		<Image style={styles.image} source={require("../../assets/app-logo.png")} />
	);
}

const styles = StyleSheet.create({
	image: {
		height: 50,
		width: 50,
		marginBottom: Platform.OS == "web" ? 0 : 5,
		alignSelf: "center",
	},
});

export default Logo;
