import React from "react";
import { Platform, Pressable, StyleSheet, Text, View, Dimensions} from "react-native";
import Colors from "../../constants/Colors";

const { height } = Dimensions.get("window");
const loadingWheel = "https://i.stack.imgur.com/kOnzy.gif";

const submitSkippedImages = (image, transcription) => {
	console.log(image);
	console.log(transcription);
	if (image.url !== loadingWheel) {
		fetch(
			"https://ri-backend-prd.byu-dept-fhtl-prd.amazon.byu.edu/mobile/image/skip",
			{
				method: "PUT",
				headers: {
					Accept: "application/json",
					"Content-Type": "application/json",
				},
				body: JSON.stringify({
					image: image,
					transcription: transcription,
				}),
			}
		);
	}
};

function SkipButton(props) {
	if (Platform.OS == "web") {
		return (
			<Pressable
				children={({ pressed }) => (
					<View style={pressed ? styles.pressedButton : styles.button}>
						<Text style={styles.text}>Skip</Text>
					</View>
				)}
				onPress={() => {
					submitSkippedImages(props.image, props.text);
					props.setImageURL();
					props.resetTextInput();
					//props.checkTimestamp();
				}}
			/>
		);
	} else {
		return (
			<Pressable
				children={({ pressed }) => (
					<View style={pressed ? styles.pressedButton : styles.button}>
						<Text style={styles.text}>Skip</Text>
					</View>
				)}
				onPress={() => {
					submitSkippedImages(props.image, props.text);
					props.setImageURL();
					props.resetTextInput();
					//props.checkTimestamp();
				}}
				style={styles.container}
			/>
		);
	}
}

const styles = StyleSheet.create({
	button: {
		backgroundColor: Colors.primary,
		borderRadius: 8,
		justifyContent: "center",
		alignItems: "center",
		height: 50,
		width: "100%",
		//marginHorizontal: 2,
	},
	pressedButton: {
		backgroundColor: Colors.pressedPrimary,
		borderRadius: 8,
		justifyContent: "center",
		alignItems: "center",
		width: "100%",
		height: 50,
	},
	text: {
		color: Colors.white,
		justifyContent: "center",
		alignItems: "center",
		fontSize: 24,
	},
	container: {
		width: "100%",
		alignItems: "center",
	},
});

export default SkipButton;
